import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "enableHR Product"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`enableHR is a cloud-based software that helps you easily manage your workers, which are defined as Candidates, Employees, Contractors and Volunteers.`}</p>
    <p>{`Pre-defined and customised workflows and checklists guide you through smart, legally compliant HR processes, enableHR helps you communicate with your employees and set them tasks.`}</p>
    <p>{`It stores worker records and has features that let managers and employees easily access, deliver, and receive documents and files at all stages throughout the employee lifecycle. It automates and makes time-consuming HR admin tasks simple, so you have more time to work on the things that matter in your business.`}</p>
    <p>{`enableHR does this via a comprehensive range of HR and safety modules, which are as follows:`}</p>
    <img src="/images/enablehr-product.png" className="w-50 mx-auto d-block" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      